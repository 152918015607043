import http from "@/axios/index";
import { URI } from "@/config/axios";

// 获取接入网关管理数据
export const getInGatewayList = (pageNum, pageSize, params) => {
  return http({
    url: URI.system + "api/tenantGateway/queryPageList",
    method: "get",
    params: {
      pageNum,
      pageSize,
      ...params,
    },
  });
};

export const updateStatus = (data) => {
  return http({
    url: URI.system + "api/tenantGateway/updateStatus",
    method: "post",
    params: data,
  });
};

//获取协议
export const getProtocolData = () => {
  return http({
    url: URI.system + "api/tenant/findProtocolByTenantId",
    method: "get",
  });
};

//获取IP域名
export const getIPData = () => {
  return http({
    url: URI.system + "api/tenantServer/queryByTenantId",
    method: "get",
  });
};

//获取设备类型
export const getDeviceTypeData = (data) => {
  return http({
    url: URI.system + "api/tenant/findByTenantIdAndProtocol",
    method: "get",
    params: data,
  });
};

//新增接入网关数据
export const addGatewayData = (data) => {
  return http({
    url: URI.system + "api/tenantGateway/add",
    method: "post",
    data: data,
  });
};

//删除接入网关数据
export const deleteGatewayData = (data) => {
  return http({
    url: URI.system + "api/tenantGateway/delete",
    method: "delete",
    params: data,
  });
};

//获取单个网关详情
export const queryGatewayById = (data) => {
  return http({
    url: URI.system + "api/tenantGateway/queryById",
    method: "get",
    params: data,
  });
};

//编辑接入网关数据
export const modifyGatewayData = (data) => {
  return http({
    url: URI.system + "api/tenantGateway/modify",
    method: "put",
    data: data,
  });
};

// 获取文件列表 GET /api/attachFile/queryList{?code,type,version}
export const queryFileList = (code, type, version) => {
  return http({
    url: URI.system + "api/attachFile/queryList",
    method: "get",
    params: { code, type, version },
  });
};
