<template>
    <div class="in-gateway">
        <!-- 面包屑 -->
        <els-bread class="_bread"></els-bread>
        <div class="_header com-top">
            <el-button size="small" type="primary" @click="handleAdd">{{lang.addGateway}}</el-button>
            <div>
                <el-input
                        size="small"
                        v-model="searchName"
                        :placeholder="lang.gateSerName" style="width:360px"
                        prefix-icon="el-icon-search"
                        @change="searchData"
                ></el-input>
            </div>
        </div>
        <div class="_content com-bottom">
            <els-table ref="listRef" :onload="onload" :column="column" :table="tableOption"
                       :menuWidth="'180px'">
                <template v-slot:protocolId="{ row }">
                    <span>{{(protocolData.find(item=>item.id===row.protocolId))?protocolData.find(item=>item.id===row.protocolId).name:""}}</span>
                </template>
                <template v-slot:menu="{ row }">
                    <el-button v-if="row.status==='0'||row.status==='1'"
                               type="text" :disabled="row.status==='0'"
                               @click.stop="handleUpdateStatus(row,'2')"
                    >{{lang.startUp}}
                    </el-button>
                    <el-button v-if="row.status==='2'"
                               type="text"
                               @click.stop="handleUpdateStatus(row,'1')"
                    >{{lang.stop}}
                    </el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button
                            type="text"
                            @click.stop="handleRowEdit(row)"
                            :disabled="row.status==='2'||row.status==='0'"
                    >{{lang.edit}}
                    </el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button
                            type="text"
                            @click.stop="handleDelete(row)"
                            class="red-btn"
                            :disabled="row.status==='2'||row.status==='0'"
                    >{{lang.delete}}
                    </el-button>
                </template>
            </els-table>
        </div>
        <els-form-dialog :visible.sync="dialogData.dialogVisible"
                         :title="dialogData.title"
                         :showFormTitle="false" :cancel="lang.cancel" :submit="lang.confirm"
                         @submit="handleSubmit" :loading="loadingBtn">
            <els-form
                    ref="formRef"
                    :column="dialogData.isEdit?editFormColumn:formColumn" v-model="formData"
                    v-if="dialogData.dialogVisible"
                    @change-protocolId="changeProtocolData" @change-ip="changeIPData"
                    @change-sdk="changeSDK"></els-form>
        </els-form-dialog>
    </div>
</template>
<script>
    import elsTable from "@/components/els-table/main.vue";
    import {
        getInGatewayList,
        updateStatus,
        getProtocolData,
        getIPData,
        getDeviceTypeData,
        addGatewayData,
        deleteGatewayData,
        queryGatewayById,
        modifyGatewayData,
        queryFileList
    } from "@/api/manager/in-gateway.js";
    import ElsFormDialog from "../../components/els-form-dialog/main";
    import elsForm from "@/components/els-form/main.vue"
    //import MachineTable from "@/components/in-gateway-manager/machine-table";
    import elsBread from "@/components/els-breadcrumb/main.vue"
    import {mapState} from "vuex";
    import mixinsI18n from "@/mixins/i18n.js"
    export default {
        name: "in-gateway",
        mixins: [mixinsI18n],
        components: {ElsFormDialog, elsTable, elsForm, elsBread},
        data() {
            return {
                PAGE_NAME: "inGatewayManagePage",
                searchName: "",
                query: {name: ""},
                formData: {},
                dialogData: {
                    title: '',
                    dialogVisible: false,
                    isEdit: false
                },
                protocolTypeData: [],
                fileList: [],
                fileListLoading:false,
                protocolData: [],
                IPData: [],
                portData: [],
                deviceTypeData: [],
                loadingBtn: false
            }

        },
        computed: {
            ...mapState(["breadMenus"]),
            column() {
                const deploy=[{label:this.lang.server,value:"0"},{label:this.lang.container,value:'1'}];
                const status=[{label:this.lang.starting,value:"0",className: "dot--default"},
                    {label:this.lang.notStart,value:'1',className: "dot--error"},
                    {label:this.lang.started,value:'2', className: "dot--success"}];
                const col1 = {label: this.lang.gateSerName, prop: "name",minWidth: 180};
                const col2 = {label: this.lang.proType, prop: "protocolId", hasSlot: true,minWidth: 120};
                const col3 = {label: this.lang.terType, prop: "deviceTypeNames",minWidth: 100};
                const col4 = {label: this.lang.depLoc, prop: "deployPosition",dict:deploy,minWidth: 80};
                const col5 = {label: this.lang.IpDomain, prop: "ip",minWidth: 100};
                const col6 = {label: this.lang.portNum, prop: "port",minWidth: 65};
                const col7 = {label: this.lang.state, prop: "status",dict:status,minWidth: 100};
                return [col1, col2, col3, col4, col5, col6, col7];
            },
            tableOption() {
                const d = {
                    border: false,
                    size: 'medium',
                }
                return d;
            },
            buttonColumn() {
                const btn1 = {text: this.lang.view, size: 'mini', type: 'text'};
                const btn2 = {text: this.lang.stop, size: 'mini', type: 'text'};
                const btn3 = {text: this.lang.delete, size: 'mini', type: 'text'};
                return [btn1, btn2, btn3];
            },
            formColumn() {
                const col1 = {
                    tag: "el-input",
                    prop: "name",
                    label: this.lang.gateSerName,
                    rules: [{required: true, message: this.lang.inputGateName, trigger: "blur"},
                        {pattern: /^[0-9a-zA-Z_]*$/, message: this.lang.inputLetNumUn},
                        {max: 50, message: this.lang.max50, trigger: 'blur'}],
                };
                const col2 = {
                    tag: 'el-select',
                    prop: 'protocolId',
                    placeholder: this.lang.pleaseSelect,
                    label: this.lang.proType,
                    width:"100%",
                    rules: [
                        {required: true, message: this.lang.selProType, trigger: "change"}],
                    cls: this.protocolData.map(item => ({value: item.id, label: item.name}))
                };
                const col3 = {
                    tag: 'el-select',
                    prop: 'deviceTypeIds',
                    placeholder: this.lang.pleaseSelect,
                    label: this.lang.terType,
                    multiple: true,
                    width:"100%",
                    rules: [
                        {required: true, message: this.lang.selTerType, trigger: "change"}],
                    cls: this.deviceTypeData.map(item => ({value: item.id, label: item.deviceTypeName})),
                };
                const col4 = {
                    tag:'el-select',
                    prop:'attachFileId',
                    label: this.lang.SDKFile,
                    placeholder:this.lang.inFileEncode,
                    filterable:true,
                    cls:this.fileList,
                    rules: [{required: true, message: this.lang.selFile, trigger: "blur"}],
                    style:'width:100%'
                }
                // const col4 = {
                //     tag: "self-upload",
                //     label: "SDK文件:",
                //     prop: "sdk",
                //     size: "mini",
                //     rules: [{required: true, message: "SDK文件未上传成功或未上传", trigger: "blur"}],
                //     fileList: this.fileList,
                //     fileLimitMess: "只能上传一个jar类型文件"
                // };
                const col5 = {
                    tag: 'el-radio-group',
                    prop: 'deployPosition',
                    label: this.lang.depLoc,
                    value:'0',
                    rules: [
                        {required: true, message: this.lang.selDepLoc, trigger: "change"}],
                    cls: [{label: '0', cls: this.lang.server},
                        {label: '1', cls: this.lang.container, disabled: true}],
                };
                const col6 = {
                    tag: 'el-select',
                    prop: 'ip',
                    placeholder: this.lang.pleaseSelect,
                    label: this.lang.IPAndDomain,
                    width:"100%",
                    rules: [
                        {required: true, message: this.lang.selIPAndDomain, trigger: "change"}],
                    cls: this.IPData.map(item => ({value: item.ip})),
                };

                const col7 = {
                    tag: "el-select",
                    placeholder: this.lang.pleaseSelect,
                    prop: "port",
                    label: this.lang.portNum,
                    width:"100%",
                    rules: [{required: true, message: this.lang.selPortNum, trigger: "change"}],
                    cls: [...this.portData]
                };
                return [col1, col2, col3, col4, col5, col6, col7];
            },
            editFormColumn() {
                const col1 = {
                    tag: "el-input",
                    prop: "name",
                    label: this.lang.gateSerName,
                    rules: [{required: true, message: this.lang.inputGateName, trigger: "blur"},
                        {pattern: /^[0-9a-zA-Z_]*$/, message: this.lang.inputLetNumUn},
                        {max: 50, message: this.lang.max50, trigger: 'blur'}],
                };
                const col2 = {
                    tag: 'el-select',
                    prop: 'protocolId',
                    placeholder: this.lang.pleaseSelect,
                    label: this.lang.proType,
                    width:"100%",
                    rules: [
                        {required: true, message: this.lang.selProType, trigger: "blur"}],
                    cls: this.protocolData.map(item => ({value: item.id, label: item.name})),
                    disabled: true
                };
                const col3 = {
                    tag: 'el-input',
                    prop: 'deviceTypeName',
                    label: this.lang.terType,
                    multiple: true,
                    rules: [
                        {required: true, message: this.lang.selTerType, trigger: "blur"}],
                    disabled: true,
                };
                const col4 = {
                    tag:'el-select',
                    prop:'attachFileId',
                    label: this.lang.SDKFile,
                    placeholder: this.lang.inFileEncode,
                    filterable:true,
                    cls:this.fileList,
                    rules: [{required: true, message: this.lang.selFile, trigger: "blur"}],
                    style:'width:100%'
                }
                // const col4 = {
                //     tag: "self-upload",
                //     label: "SDK文件:",
                //     prop: "sdk",
                //     size: "mini",
                //     rules: [{required: true, message: "SDK文件未上传成功或未上传", trigger: "blur"}],
                //     // fileList: this.fileList,
                //     fileLimitMess: "只能上传一个jar类型文件"
                // };
                const col5 = {
                    tag: 'el-radio-group',
                    prop: 'deployPosition',
                    label: this.lang.depLoc,
                    rules: [
                        {required: true, message: this.lang.selDepLoc, trigger: "change"}],
                    cls: [{label: '0', cls: this.lang.server},
                        {label: '1', cls: this.lang.container, disabled: true}],
                };
                const col6 = {
                    tag: 'el-input',
                    prop: 'ip',
                    label: this.lang.IPAndDomain,
                    rules: [
                        {required: true, message: this.lang.selIPAndDomain, trigger: "blur"}],
                    disabled: true
                };

                const col7 = {
                    tag: "el-input",
                    prop: "port",
                    label: this.lang.portNum,
                    rules: [{required: true, message: this.lang.selPortNum, trigger: "blur"}],
                    disabled: true,
                };
                return [col1, col2, col3, col4, col5, col6, col7];
            }
        },
        created() {
            // this.getProtocolList();
            this.getIPList();
            //
            this.queryFileList();
        },
        watch: {
            searchName(v) {
                this.query = {
                    name: this.searchName
                }
                this.$refs.listRef.apiOnload();
            }
        },
        methods: {
            async onload(page, query = {}) {
                const pn = page.pageNum;
                const ps = page.pageSize;
                const q = this.query || {};
                const params = {...query, ...q}
                try {
                    if (this.protocolData.length === 0) {
                        await this.getProtocolList();
                    }
                    const res = await getInGatewayList(pn, ps, params);
                    if (res.status !== 200) {
                        return {}
                    }
                    const list = res.result.data
                    const total = Number(res.result.total)
                    return {list, total}
                } catch (e) {
                    console.log(e)
                }
                return {}
            },
            // 获取下拉列表
            async queryFileList(code,type=0,version){
                try{
                    const res = await queryFileList(code,type,version);
                    if(res.status != 200){
                        this.$message.error(res.msg)
                        return ;
                    }
                    //
                    this.fileList = res.result.filter(item => item.status == 0).map(item => ({key:item.id,label:item.code,value:item.id}))
                }catch(e){console.error(e)}
            },
            searchData() {
                this.query = {
                    name: this.searchName
                }
                this.$refs.listRef.apiOnload();
            },
            resetData() {
                this.searchName = "";
                this.query = {
                    name: this.searchName
                }
                this.$refs.listRef.apiSetPageNum();
                this.$refs.listRef.apiOnload();
            },
            //启动或停止
            handleUpdateStatus(row, state) {
                this.$confirm(this.lang.startUpMsg, this.lang.tips, {
                    type: "warning",
                    confirmButtonText: this.lang.confirm,
                    cancelButtonText: this.lang.cancel,
                    closeOnClickModal: false,
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = this.lang.inExecute;
                            updateStatus({id: row.id, status: state}).then((res) => {
                                instance.confirmButtonLoading = false;
                                done();
                                this.$message.success(res.msg);
                                this.searchData();
                                if(state==='2') {
                                    const this1=this;
                                    setTimeout(function() {
                                        this1.searchData();
                                    }, 10000);
                                }
                            }).catch((err) => {
                                done();
                                instance.confirmButtonLoading = false;
                            })
                        } else {
                            done();
                        }
                    }
                }).then(() => {
                    },
                    otherwise => {
                    })
            },
            // 点击新增
            handleAdd() {
                this.dialogData.title = this.lang.addGateway;
                this.dialogData.dialogVisible = true;
                this.dialogData.isEdit = false;
                this.formData = {};
                this.deviceTypeData = [];
                this.portData = [];
                this.$nextTick(() => {
                    if(this.$refs['formRef']) {
                        this.$refs['formRef'].clearValidate();
                    }
                })
            },
            //点击编辑
            handleRowEdit(row) {
                //this.$refs['formRef'].resetFields();
                this.dialogData.title = this.lang.editGateway;
                this.dialogData.dialogVisible = true;
                this.dialogData.isEdit = true;
                this.formData = {};
                queryGatewayById({id: row.id}).then((res) => {
                    const sdk = res.result.sdk;
                    this.formData = res.result;
                    this.formData = {...this.formData, deviceTypeName: row.deviceTypeNames, ip: row.ip};
                    // this.fileList = [{name: sdk.substring(sdk.indexOf("-") + 1), url: res.result.sdkUrl}];
                })
            },
            async handleSubmit() {
                this.loadingBtn = true;
                try {
                    await this.$refs['formRef'].validate();
                    if (this.formData.id) {
                        let info = {...this.formData};
                        delete info.sdkUrl;
                        modifyGatewayData(info).then((res) => {
                            this.loadingBtn = false;
                            this.$message.success(res.msg);
                            this.dialogData.dialogVisible = false;
                            this.searchData();
                        }).catch((err) => {
                            this.loadingBtn = false;
                        })
                    } else {
                        let info = {...this.formData};
                        info.deviceTypeIds = info.deviceTypeIds.toString();
                        addGatewayData(info).then((res) => {
                            this.loadingBtn = false;
                            this.$message.success(res.msg);
                            this.dialogData.dialogVisible = false;
                            this.resetData();
                        }).catch((err) => {
                            this.loadingBtn = false;
                        })
                    }
                } catch (e) {
                    this.loadingBtn = false;
                    console.log(e)
                }
            },
            //协议类型改变
            changeProtocolData(v) {
                this.formData = {...this.formData, deviceTypeIds: ''};
                const data = this.protocolData.find(item => item.id === v);
                this.deviceTypeData = [];
                getDeviceTypeData({protocol: data.name}).then((res) => {
                    this.deviceTypeData = res;
                })
            },
            //获取协议数据
            async getProtocolList() {
                return new Promise((resolve, reject) => {
                    getProtocolData().then((res) => {
                        this.protocolData = res;
                        resolve(res)
                    }).catch(err => {
                        reject()
                    });
                });
            },
            //获取IP域名
            getIPList() {
                getIPData().then((res) => {
                    this.IPData = res;
                })
            },
            //ip域名改变时
            changeIPData(val) {
                const ip = this.IPData.find(item => item.ip === val);
                this.portData = ip.ports.split(",").map(item => ({value: item}));
                this.formData = {...this.formData, port: '', serverId: ip.id};
            },
            //删除网关
            handleDelete(row) {
                this.$confirm(this.lang.delMsg, this.lang.tips,  {
                    confirmButtonText: this.lang.confirm,
                    cancelButtonText: this.lang.cancel,
                    type: "warning",
                    closeOnClickModal: false,
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = this.lang.inExecute;
                            deleteGatewayData({id: row.id}).then((res) => {
                                instance.confirmButtonLoading = false;
                                done();
                                this.$message.success(res.msg);
                                this.searchData();
                            }).catch((err) => {
                                done();
                                instance.confirmButtonLoading = false;
                            })
                        } else {
                            done();
                        }
                    }
                }).then(() => {
                    },
                    otherwise => {
                    })
            },
            //获取单个网关详情
            getGatewayDetail(row) {
                queryGatewayById({id: row.id}).then((res) => {

                })
            },
            // 上传文件改变时
            changeSDK(v) {
                if(v) {
                    this.$refs['formRef'].validateField('sdk');
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .in-gateway {
        padding: 0 24px 24px 24px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .rm--bread {
            height: 44px;
            display: flex;
            align-items: center;
        }

        .com-top {
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 12px;
        }

        .com-bottom {
            height: calc(100% - 44px - 56px);
        }
    }
    ::v-deep .el-button.red-btn:not(.is-disabled) {
            color:#F74153;
    }
</style>
